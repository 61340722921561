import React from 'react'
import './CareerDetailBanner.css'
import { Button, Col, Container, Row } from 'react-bootstrap'

const CareerDetailBanner = ({title,onClick,shift}) => {

    return (
        <div>
            <>
                <div className='careerbanner-bg'>
                    <Container>
                        <Row>
                            <Col xl='7' className='banner-content'>
                                <p>Technogenesis Pvt. Ltd. | {shift}</p>
                                <h1>{title}</h1>
                                <Button className='apply_now_btn' onClick={onClick} variant="">Apply Now</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
             </>
        </div>
    )
}

export default CareerDetailBanner 