import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import '../../../src/App.css'
import './careerdetail.css'
import Footer from '../../components/footer/Footer'
import Navigation from '../../components/navigation/Navigation'
import Banner from '../../components/banner/banner';
import { BiSolidShoppingBags } from 'react-icons/bi'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { BiTimeFive } from 'react-icons/bi'
import { ImLocation } from 'react-icons/im'
import Button from 'react-bootstrap/Button';
import { Link, useParams } from 'react-router-dom';
import Newnavigation from '../../components/new-navigation/new_navigation';
import ContactNew from '../../components/contact-form/ContactNew';
import JobApply from '../../components/job-apply-model/JobApply';
import CareerDetailBanner from '../../components/CareerDetailBanner/CareerDetailBanner';
import { JobCard } from '../../components/job-card/job-card';
import moment from "moment";
import PageLoader from '../../components/page-loader/PageLoader';
import { apiEnvironment } from '../../api';
import axios from 'axios';
const CareerDetail = ({viewData}) => {

   
   const { id } = useParams();
   const [activeItem, setActiveItem] = useState(null);
   const [showOffcanvas, setShowOffcanvas] = useState(false);
 
   const handleShow = (item) => {
     setActiveItem(item);
     setShowOffcanvas(true);
     console.log(item)
   };
 
   const handleClose = () => {
     setShowOffcanvas(false);
     setActiveItem(null);
   };
   const [loader, setLoader] = useState(false);
   const [items, setItems] = useState();

   useEffect(() => {
      window.scroll({
         top: 0,
         behavior: "smooth",
      });
   }, []);

   useEffect(() => {
      setLoader(true);
      const fetchData = async () => {
        try {
         const res = await axios.get(`${apiEnvironment?.testUrl}/jobs/web/${id}`);
         //  const res = await axiosInstance.get(`/jobs/web/${id}`);
          if (res?.data?.status != true) {
            setLoader(false);
  
            // navigate("/404-not-found", { replace: true });
          } else {
            setItems(res?.data?.data);
            setLoader(false);
            console.log(res?.data?.data);
          }
        } catch (e) {
          setLoader(false);
        }
      };
      fetchData();
    }, [id]);
 
   const findDays = (data) => {
      const parsedDate = moment(data);
      if (!parsedDate.isValid()) {
        return "Invalid date";
      }
      
      const now = moment();
      const diffYears = now.diff(parsedDate, "years");
      const diffMonths = now.diff(parsedDate, "months");
      const diffDays = now.diff(parsedDate, "days");
      const diffHours = now.diff(parsedDate, "hours");
      const diffMin = now.diff(parsedDate, "minutes");
    
      // Determine relative time
      let relativeTime;
    
      if (diffYears === 1) {
        relativeTime = "1 year ago";
      } else if (diffYears > 1) {
        relativeTime = `${diffYears} years ago`;
      } else if (diffMonths === 1) {
        relativeTime = "1 month ago";
      } else if (diffMonths > 1) {
        relativeTime = `${diffMonths} months ago`;
      } else if (diffDays === 1) {
        relativeTime = "1 day ago";
      } else if (diffDays > 1) {
        relativeTime = `${diffDays} days ago`;
      } else if (diffHours === 1) {
        relativeTime = "1 hour ago";
      } else if (diffHours > 1) {
        relativeTime = `${diffHours} hours ago`;
      } else if (diffMin === 1) {
        relativeTime = "1 minute ago";
      } else if (diffMin > 1) {
        relativeTime = `${diffMin} minutes ago`;
      } else {
        relativeTime = "A few seconds ago";
      }
    
      return relativeTime;
    };
    
   return (
      <div>
         <Newnavigation routeName="About-us" />
         <CareerDetailBanner title={items?.jobTitle} shift={items?.job_shift.shiftName} onClick={() => handleShow(items?.id,items?.jobTitle)}/>
         <Container className='mt-75 mb-75'>
         <Row>
                <Col lg={8} data-aos="fade-up" data-aos-duration="1000">
                  <div className='job-view-section'>
                    <span className='post_date'>  
                     {moment(items?.createdAt).format("MMMM DD, YYYY")}</span>
                    <h2 className='job_title'>{items?.jobTitle}</h2>
                    <div className='job_keys'>
                            <div className='job_keys_cont'>
                                 <BiTimeFive />
                                 <p> {findDays(items?.createdAt)}</p>
                            </div>
                            <div className='job_keys_cont'>
                                 <ImLocation />
                                 <p>{items?.district.districtName}, {items?.state.stateName}</p>
                            </div>
                            <div className='job_keys_cont'>
                                 <BiSolidShoppingBags />
                                 <p>{items?.job_shift.shiftName}</p>
                            </div>
                    </div>
                    <div className='job_overview'>
                        <p>Job Title:<span>&nbsp;{items?.jobTitle}</span></p>
                        <p>Job Category:<span>&nbsp;{items?.category.categoryName}</span></p>
                        <p>No of positions:<span>&nbsp;{items?.noOfPositions}</span></p>

                    </div>
                    <div className='job_view_description'>
                        <h5>Job Description:</h5>
                        <div dangerouslySetInnerHTML={{ __html: items?.description }} />
                    </div>
                    <div className='job_view_description'>
                        <h5>Responsibilities:</h5>
                        <div dangerouslySetInnerHTML={{ __html: items?.keyResponsibility }} />
                    </div>
                    <div className='job_view_description'>
                        <h5>Skills Required:</h5>
                        <div className='chip-group'>
                         {items?.skills.map((skill, index) => (
                           <span className='chip' key={index}>{skill.skillName}</span>
                        ))}  
                        </div>
                     </div>
                   <button className='apply_now' onClick={() => handleShow(items?.id,items?.jobTitle)}>Apply Now</button>
                  </div>
                </Col>
                <Col lg={{span: 3 , offset:1}} data-aos="fade-up"data-aos-duration="1000">
                 <div className='job-info-section'>

                      <h2 className='job_title '>Job Information</h2>
                      <div className='job-info-overview'>
                      <div className='job-info-section-container'>
                        <span> Gender</span>
                        <h5>{(items?.gender)?.charAt(0) + items?.gender?.slice(1)?.toLowerCase()}</h5>
                     </div>
                        <div className='job-info-section-container'>
                        <span>Experience</span>
                        <h5>{items?.experience === 0 ? "Fresher" : items?.experience === 1 ? items?.experience + "  " + "Year" :  items?.experience + "  " + "Years"}</h5>
                     </div>
                     { 
                        items?.hideSalary  != true ? (
                           <div className='job-info-section-container'>
                           <span>Salary</span>
                           <h5>{items?.salaryFrom} - {items?.salaryTo} LPA</h5>
                        </div>
                        ) : null

                     }
                   
                     <div className='job-info-section-container'>
                        <span>Posting Date</span>
                        <h5>{moment(items?.createdAt).format("DD/MM/YYYY")}</h5>
                     </div>
                     <div className='job-info-section-container'>
                        <span>Expiring Date</span>
                        <h5>{moment(items?.jobExpiryDate).format("DD/MM/YYYY")}</h5>
                     </div>
                     <div className='job-info-section-container'>
                        <span>Career Level</span>
                        <h5>{items?.carrier.careerName}</h5>
                     </div>
                     <div className='job-info-section-container'>
                        <span>Degree Level</span>
                        <h5>{items?.DegreeLevel.degreeName}</h5>
                     </div>
                     <div className='job-info-section-container'>
                        <span>Is Freelance</span>
                        <h5>{ items?.isFreelance == true ? 'Yes' : 'No' }</h5>
                     </div>
                   
                     </div>
                     <div className='job-info-section-container'>
                        <span>Job Tag</span>
                        <div className='chip-group'>
                        {items?.jobTag?.map((tag, index) => (
                          <span className='chip' key={index}>{tag}</span>
                        ))}
                        </div>
                    </div>
                    <div className='job-info-section-container'>
                        <span>{items?.customField}</span>
                        <div className='chip-group'>
                        {items?.customFieldValue?.map((custom_field, index) => (
                          <span className='chip' key={index}>{custom_field}</span>
                        ))}
                        </div>
                    </div>
                 </div>
                </Col>
              </Row>
         </Container>
         {/* <div>
            <Container>
               <h2 className='section_title'>Other Positions</h2>
            </Container>
            <JobCard data={jobs} />
         </div> */}

         <ContactNew />
         <Footer />
         {loader == true ? <PageLoader /> : null}
         <JobApply show={showOffcanvas} activeItem={activeItem} onClose={handleClose} placement='end' onShow={handleShow} job_Id={items?.id} job_title={items?.jobTitle} />
      </div>
   )
}
export default CareerDetail;