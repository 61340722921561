import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const JobCardLoder = () => {
  return (
    <Row>
      <Col lg={12} className="job_column">
        <div className="job-card">
          <div className="job_cont">
            <SkeletonTheme>
              <Skeleton count={1} borderRadius={8} width={'30%'} style={{ height: "24px" }} />
              <div
                className="d-flex "
                style={{ justifyItems: "center", width: "100%", gap: "20px", flexWrap:"wrap"}}
              >
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "16px", width: "90px", marginTop: "20px" }}
                />
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "16px", width: "90px", marginTop: "20px" }}
                />
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "16px", width: "130px", marginTop: "20px" }}
                />
              </div>

              <div className="mt-3">
                <Skeleton
                  count={4}
                  borderRadius={8}
                  style={{ height: "14px", width: "90%" }}
                />
              </div>

              <div
                className="d-flex mb-3"
                style={{ justifyItems: "center", width: "100%", gap: "20px" }}
              >
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "30px", width: "150px", marginTop: "20px" }}
                />
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "30px", width: "150px", marginTop: "20px" }}
                />
              </div>
            </SkeletonTheme>
          </div>
        </div>
      </Col>
      <Col lg={12} className="job_column">
        <div className="job-card">
          <div className="job_cont">
            <SkeletonTheme>
              <Skeleton count={1} borderRadius={8} width={'30%'} style={{ height: "24px" }} />
              <div
                className="d-flex "
                style={{ justifyItems: "center", width: "100%", gap: "20px", flexWrap:"wrap"}}
              >
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "16px", width: "90px", marginTop: "20px" }}
                />
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "16px", width: "90px", marginTop: "20px" }}
                />
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "16px", width: "130px", marginTop: "20px" }}
                />
              </div>

              <div className="mt-3">
                <Skeleton
                  count={4}
                  borderRadius={8}
                  style={{ height: "14px", width: "90%" }}
                />
              </div>

              <div
                className="d-flex mb-3"
                style={{ justifyItems: "center", width: "100%", gap: "20px" }}
              >
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "30px", width: "150px", marginTop: "20px" }}
                />
                <Skeleton
                  count={1}
                  borderRadius={8}
                  style={{ height: "30px", width: "150px", marginTop: "20px" }}
                />
              </div>
            </SkeletonTheme>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default JobCardLoder;
