import React, { memo, useRef, useState } from "react";
import "./jobapply.css";
import { Button, Col, Modal, Offcanvas, Row, Spinner } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import { FILEUPLOAD, SUCCESSGIF } from "../../assets/images";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { apiEnvironment } from "../../api";

const JobApply = ({ job_Id, job_title, show, onClose }) => {

  // const { show, onClose} = props;
  console.log("Received job_Id:", job_Id, job_title);
  // console.log(props, 'asssssssss')
  const [applied, setApplied] = useState(false)
  const [showed, setShowed] = useState(false);
  const handleCloseSecond = () => setShowed(false);
  const { id } = useParams();
  const [buttonloader, setButtonloader] = useState(false);
  const [files, setFiles] = useState();
  const [response, setResponse] = useState();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      emailId: "",
      phone: "",
      resume: "",
      coverLetter: "",
      jobId: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required("Name is required")
        .min(3, "Name must be at least 3 characters")
        .max(100, "Name cannot exceed 100 characters"),
      emailId: Yup.string()
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email id"
        )
        .min(2, "Email must be at least 3 characters")
        .max(255, "Name cannot exceed 100 characters"),
      phone: Yup.string()
        .required("Phone no is required")
        .matches(
          /^(?!(0{10}))[^1-5]\d{9}$/,
          "Please enter a valid phone number"
        ),
      resume: Yup.mixed().required("Resume is required"),
      coverLetter: Yup.string()
        .required("Message is required")
        .max(3000, "Message cannot exceed 3000 characters"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      const bodyData = {
        fullName: values?.fullName,
        phone: values?.phone,
        emailId: values?.emailId,
        resume: values?.resume,
        coverLetter: values?.coverLetter,
        jobId: `${job_Id}`,
      };

      setButtonloader(true);

      try {
        const res = await axios.post(`${apiEnvironment?.testUrl}/jobs/apply`, bodyData);
        setResponse(res?.data);

        if (res?.data?.status == true) {
          setButtonloader(false);
          Swal.fire({
            title: "Success!",
            text: "Your application has been submitted successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            formik.resetForm();
            formik.setFieldValue("resume", "");
            setFiles("");
            onClose()
          })

        }
        else{
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            formik.resetForm();
            formik.setFieldValue("resume", "");
            setFiles("");
            onClose()
          })

        }

        if (res?.data?.statusCode === 429) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            formik.resetForm();
            formik.setFieldValue("resume", "");
            setFiles("");
            onClose()
          })

        }

        if (res?.data?.statusCode === 400) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            formik.resetForm();
            formik.setFieldValue("resume", "");
            setFiles("");
            onClose()
          })

        }

        if (res?.data?.statusCode === 500) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            formik.resetForm();
            formik.setFieldValue("resume", "");
            setFiles("");
            onClose()
          })

        }

        if (res?.data?.statusCode === 404) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            formik.resetForm();
            formik.setFieldValue("resume", "");
            setFiles("");
            onClose()
          })

        }
      } catch (err) {
        setButtonloader(false);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },
  });

  const postFile = async (file) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const postData = await axios.post(`${apiEnvironment?.testUrl}/upload`, { file }, { headers });

    formik.setFieldValue("resume", postData?.data?.image);


    return postData;
  };

  const handleResumeClear = () => {

    setFiles(null)
    formik.setFieldValue("resume", "");


  }

  return (
    <>
      <Offcanvas show={show} placement='end'>
        <Offcanvas.Header>
          <Offcanvas.Title>Apply for {job_title}</Offcanvas.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={onClose}
          ></button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form autocomplete="off" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group
                  className="mb-3 margin_form"
                  controlId="formBasicFullname"
                >
                  <Form.Label>
                    Full Name <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    {...formik.getFieldProps("fullName")}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <small className="text-danger d-block mt-1 ms-1">
                      {formik.errors.fullName}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className="mb-3 margin_form"
                  controlId="formBasicPhone"
                >
                  <Form.Label>
                    Phone No <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Phone No"
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <small className="text-danger d-block mt-1 ms-1">
                      {formik.errors.phone}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group
                  className="mb-3 margin_form"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    Email <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Id"
                    {...formik.getFieldProps("emailId")}
                  />
                  {formik.touched.emailId && formik.errors.emailId ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.emailId}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="h-100">
                  <Form.Control
                    type="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];

                      if (file) {
                        const fileType = file.type;
                        const fileSize = file.size;
                        const validFileTypes = [
                          "application/pdf",
                          "application/msword",
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          "text/plain",
                          "application/rtf",
                        ];
                        const maxFileSize = 2 * 1024 * 1024;

                        if (validFileTypes.includes(fileType)) {
                          if (fileSize <= maxFileSize) {
                            setFiles(file.name);
                            postFile(file);
                          } else {
                            Swal.fire({
                              title: "Error!",
                              text: "File is too large. Maximum allowed size is 2MB.",
                              icon: "error",
                              confirmButtonText: "OK",
                            });
                          }
                        } else {
                          Swal.fire({
                            title: "Error!",
                            text: "Invalid file type. Please upload a PDF, DOCX, DOC, RTF, or TXT file.",
                            icon: "error",
                            confirmButtonText: "OK",
                          });
                        }

                        event.currentTarget.value = null;
                      }
                    }}
                    className="d-none"
                    id="customFileUpload"
                  />
                  <label
                    className="upload_container"
                    htmlFor="customFileUpload"
                  >
                    <img src={FILEUPLOAD} alt="uploader" />

                    <div>
                      <h5>Upload CV</h5>
                      <p>Use a pdf, docx, doc, rtf & txt and size should not exceed 2mb  </p>
                    </div>
                  </label>
                  {files && (
                    <p className="uploaded_file">
                      <em>
                        <strong> Uploaded file :</strong><span className="chip_design"> <span className="text-crop">{files}</span>  <IoClose className="clear_resume" onClick={() => handleResumeClear()} /></span>
                      </em>
                    </p>
                  )}
                  {formik.touched.resume && formik.errors.resume ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.resume}
                    </small>
                  ) : null}
                </Form.Group>
                {/* <button
                  className="file_upload_job margin_form"
                  onClick={onBtnClick}
                >
                  <div className="btn-flex">
                    <img src={FILEUPLOAD} alt="icon" />
                    <div className="ml-2">
                      <h6>Upload CV</h6>
                      <p>Use a pdf, docx, doc, rtf & txt</p>
                    </div>
                  </div>
                </button> */}

                {/* <Form.Control
                  type="file"
                  size="lg"
                  className="d-none"
                  ref={inputFileRef}
                  onChangeCapture={onFileChangeCapture}
                /> */}
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group
                  className="mb-3 margin_form"
                  controlId="formBasicCover"
                >
                  <Form.Label>
                    Cover letter <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={4}
                    placeholder="Enter Cover Letter..."
                    {...formik.getFieldProps("coverLetter")}
                  />
                  {formik.touched.coverLetter && formik.errors.coverLetter ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.coverLetter}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Button className="apply_now_btn" type="submit">
              {buttonloader === true ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                />
              ) : null}
              <span> {buttonloader === true ? null : "Submit"} </span>
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      {/* <Modal show={show} centered  size="lg">
       <Modal.Header>
      <Modal.Title>Apply Now </Modal.Title>
      <Button variant="light" onClick={onClose}><AiOutlineClose /></Button>
       </Modal.Header>
        <Modal.Body>

     <Form>

        <Row>
            <Col lg={6}>
            <Form.Group className="mb-3 margin_form" controlId="formBasicFullname">
        <Form.Label>Full Name <span className='required'>*</span></Form.Label>
        <Form.Control type="text" placeholder="Enter full name" />
        
      </Form.Group>

            </Col>
            <Col lg={6}>
            <Form.Group className="mb-3 margin_form" controlId="formBasicPhone">
        <Form.Label>Phone No <span className='required'>*</span></Form.Label>
        <Form.Control type="number" placeholder="Enter phone no" />
        
      </Form.Group>

            </Col>
        </Row>
        <Row>
            <Col lg={6}>
            <Form.Group className="mb-3 margin_form" controlId="formBasicEmail">
        <Form.Label>Email <span className='required'>*</span></Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
        
      </Form.Group>

            </Col>
            <Col lg={6}>




            <button className='file_upload_job margin_form' onClick={onBtnClick}>
                <div className='btn-flex'>
                <img src={FILEUPLOAD} alt="icon" />
                    <div className='ml-2'>
                        <h6>Upload CV</h6>
                        <p>Use a pdf, docx, doc, rtf & txt</p>
                        
                    </div>
                </div>
          
                    

                
                
           
                </button>



            <Form.Control type="file" size='lg'  className='d-none'       
             ref={inputFileRef}
        onChangeCapture={onFileChangeCapture}/>
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
            <Form.Group className="mb-3 margin_form" controlId="formBasicCover">
        <Form.Label>Cover letter <span className='required'>*</span></Form.Label>
        <Form.Control
          as="textarea"
          placeholder="cover letter"
          style={{ height: '100px' }}
        />
        
      </Form.Group>

            </Col>
          
        </Row>

     

    
     
      <Button className='apply_now px-4 py-2 apply'  type="submit">
        Apply
      </Button>
    </Form>

            
           
          
            


        </Modal.Body>
      
      </Modal> */}

      <Modal
        className="new-model"
        show={showed}
        onHide={handleCloseSecond}
        centered
      >
        {applied == false ? (
          <div className="success_popup_form">
            <AiOutlineClose
              className="close_btn_success"
              onClick={handleCloseSecond}
            />

            <div className="success_popup_content job_apply">
              <div>
                <img src={SUCCESSGIF} alt="success_gif" />
                <h1>Thank You!</h1>
                <p>
                  We will review your application and get back to you shortly
                </p>
              </div>

              <button className="job_btn_pop">Other Job Openings</button>
            </div>
          </div>
        ) : (
          <div className="success_popup_form">
            <AiOutlineClose
              className="close_btn_success"
              onClick={handleCloseSecond}
            />

            <div className="success_popup_content job_apply">
              <div>
                <img src={SUCCESSGIF} alt="success_gif" />
                <h1>Already Applied</h1>
                <p>
                  We will review your application and get back to you shortly
                </p>
              </div>

              <button className="job_btn_pop">Other Job Openings</button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default JobApply;
