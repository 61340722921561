import React, { memo, useEffect, useRef, useState } from 'react'
import './job-card.css'
import { JOBTYPE, EXP, MAP, NO_OPENINGS } from '../../assets/images/index'
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import JobApply from '../job-apply-model/JobApply';
import moment from 'moment/moment';
import { apiEnvironment } from '../../api';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import JobCardLoder from '../Job-card-loder/JobCardLoder';


const JobCard = () => {

  const containerRef = useRef();
  const [activeItem, setActiveItem] = useState(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);

  const handleShow = (id, title) => {
    setActiveItem(id);
    setShowOffcanvas(true);
    setJobId(id);
    setJobTitle(title);
    console.log(jobId, 'job idddddddddd');
    console.log(jobTitle, 'job title');
  };

  const handleClose = () => {
    setShowOffcanvas(false);
    setActiveItem(null);
    // setJobId(null);
  };

  // const activeItem = data.find(item => item.id === activeId);
  const limit = 1; // Set your limit here
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [thresholdValue, setThresholdValue] = useState("1300px")

  let navigate = useNavigate();


  const fetchData = async () => {
    setFetching(true);
    try {

      const res = await axios.get(`${apiEnvironment?.testUrl}/jobs/web/list?limit=${limit}&offset=${offset}`);

      if (res.data.status === false) {
        setHasMore(false);
      }
      if (!res?.data?.status) {
        setHasMore(false);
        return;
      } else {
        const newItems = res?.data?.data;
        setItems((prevItems) => {
          const itemIds = prevItems.map(item => item.id);
          const filteredNewItems = newItems.filter(item => !itemIds.includes(item.id));
          return [...prevItems, ...filteredNewItems];
        });
        setOffset(offset + 1);
      }
    } catch (e) {
      setHasMore(false);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchData();
    if (window.innerWidth < 540) {
      setThresholdValue("2500px")
    }
  }, []);

  const findDays = (data) => {
    const parsedDate = moment(data);
    if (!parsedDate.isValid()) {
      return "Invalid date";
    }

    const now = moment();
    const diffYears = now.diff(parsedDate, "years");
    const diffMonths = now.diff(parsedDate, "months");
    const diffDays = now.diff(parsedDate, "days");
    const diffHours = now.diff(parsedDate, "hours");
    const diffMin = now.diff(parsedDate, "minutes");

    // Determine relative time
    let relativeTime;

    if (diffYears === 1) {
      relativeTime = "1 year ago";
    } else if (diffYears > 1) {
      relativeTime = `${diffYears} years ago`;
    } else if (diffMonths === 1) {
      relativeTime = "1 month ago";
    } else if (diffMonths > 1) {
      relativeTime = `${diffMonths} months ago`;
    } else if (diffDays === 1) {
      relativeTime = "1 day ago";
    } else if (diffDays > 1) {
      relativeTime = `${diffDays} days ago`;
    } else if (diffHours === 1) {
      relativeTime = "1 hour ago";
    } else if (diffHours > 1) {
      relativeTime = `${diffHours} hours ago`;
    } else if (diffMin === 1) {
      relativeTime = "1 minute ago";
    } else if (diffMin > 1) {
      relativeTime = `${diffMin} minutes ago`;
    } else {
      relativeTime = "A few seconds ago";
    }
    return relativeTime;
  };

  const generateUrl = (name) => {

    const urlFriendlyName = name
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/^-+/g, '')
      .replace(/-+$/g, '');
    return urlFriendlyName;
  }


  return (
    <>
      <Container>
        <div className='career-position'>
          <InfiniteScroll
            dataLength={items?.length}
            next={fetchData}
            hasMore={hasMore}
            scrollThreshold={thresholdValue}
            loader={
              <JobCardLoder />
            }
            ref={containerRef}>
            {items.length != 0 ? items.map((job, i) => {

              return (
                <Card className='job-card' key={i}>
                  <Card.Title className='job-title mb-2'>{job.jobTitle}</Card.Title>
                  <div className='job-d-flex align-items-center'>
                    <p class='d-flex align-items-center'><img src={EXP} width="25px" height="28px" /><span class="ms-1">{findDays(job?.createdAt)}</span></p>
                    <p class="ml-30 d-flex align-items-center"><img src={MAP} width="28px" height="28px" /><span class="ms-1"> {job?.district?.districtName},{" "}
                      {job?.state?.stateName}</span></p>
                    <p class="ml-30 d-flex align-items-center"><img src={JOBTYPE} width="28px" height="28px" /><span class="ms-1">{job.job_shift?.shiftName}</span></p>
                  </div>
                  <p className='description' dangerouslySetInnerHTML={{
                    __html: `${(job?.description).length > 400
                      ? (job?.description).substring(0, 400) +
                      `...`
                      : job?.description
                      }`,
                  }}></p>

                  <div className='action-btn'>
                    <Button className='apply_now_btn' onClick={() => handleShow(job.id, job.jobTitle)} variant="">Apply Now</Button>

                    <Link
                      to={`/careers-detail/${generateUrl(job?.jobTitle)}/${job?.id}`}
                      className="view_detail_btn"
                    >
                      View More Details
                    </Link>

                  </div>


                </Card>
              )
            }) : <div className='positions'>
              <div className='no-positions'>
                <img src={NO_OPENINGS} alt="no-openings-image" />
                <p>Currently no openings in <br /> technogenesis</p>
              </div>
            </div>
            }
          </InfiniteScroll>
        </div>
      </Container>
      <JobApply show={showOffcanvas} onClose={handleClose} activeItem={activeItem} onShow={handleShow} job_Id={jobId} job_title={jobTitle} />
    </>
  )
}
export default JobCard;