import React, { useEffect } from "react";
import Banner from "../../components/banner/banner";
import { CASE_BG_1, CASE_IMG_1, PHARM_SUBBANNER, WORKCARD_BG_3, WORKCARD_IMG_3 } from "../../assets/images";
import Newnavigation from "../../components/new-navigation/new_navigation";
import { Col, Container, Row } from "react-bootstrap";
import RelatedCaseStudy from "../../components/case-studies/RelatedCaseStudy";
import ContactNew from "../../components/contact-form/ContactNew";
import Footer from "../../components/footer/Footer";
import HeaderSeo from "../../components/HeaderSeo/Headerseo";
import { CaseStudySeo } from "../../utils/Seo";
import { motion } from "framer-motion";
import { Fade } from "react-awesome-reveal";
import { SimpleCard } from "../../components/simple-card/SimpleCard";
import './case-study.css'
import CaseResults from "../../components/case-study-result/CaseResults";
import CaseCard from "../../components/case-card/CaseCard";
import ServiceSubBanner from "../../components/service-sub-banner/ServiceSubBanner";

const Jewel = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const simplecardData = [
    {
      title: "Standardized Workflows",
      content:
        "Established standardized processes for each stage of jewelry manufacturing to reduce variability and ensure consistent quality.",
    },
    {
      title: "Live Tracking",
      content:
        "Implemented live tracking indicators at workstations to highlight quality standards, enabling workers to quickly identify deviations and address them",
    },
    {
      title: "Digital Inspection Checklists",
      content:
        "Quality inspectors used tablets to access digital checklists for different product components, ensuring accurate and efficient inspections",
    },
    {
      title: "Real-Time Reporting",
      content:
        "Inspectors could submit reports directly from tablets, enabling real-time tracking of quality issues and corrective actions.",
    },
  ];

  const card_data = [
    {
        id:"01",
        title:"Consistent Quality",
        content:"Standardized workflows and visual management improved product quality, resulting in reduced defects and variations."
    },
    {
        id:"02",
        title:"Engaged Workforce",
        content:"Involving employees in continuous improvement initiatives enhanced their engagement and ownership of product quality."
    },
    {
        id:"03",
        title:"Reduced Defects",
        content:"Automated alerts and real-time tracking minimized the occurrence of quality-related issues, leading to a reduction in rework."
    },
    {
        id:"04",
        title:"Efficient Inspections",
        content:"Digital checklists and image recognition expedited the inspection process, reducing inspection time and improving efficiency."
    },
   

  ]

   const caseCardData =[

    {
        title:"Quality Consistency",
        content:"Maintaining consistent quality across a diverse vendor base was a challenge, leading to variations in the final products."
    },
    {
        title:"Raw Material Traceability",
        content:"Tracing the origin of raw materials, such as gemstones and precious metals, was critical but challenging."
    },
    {
        title:"Vendor Onboarding",
        content:"Onboarding new vendors while ensuring their products met quality standards was a complex and time-consuming process."
    },
    {
      title:"Defect Identification",
      content:"Identifying defects in intricate jewelry designs was often delayed, affecting product quality and customer satisfaction."
  },

   ]


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="work_page ">
        <HeaderSeo data={CaseStudySeo} />
        <Newnavigation isblack={true} />
        <div id="Overview">
        <Banner
width={'65%'}
bg={WORKCARD_BG_3}
smalltext={'Case Study 3'}
title={'Jewellery Manufacturing'}
img={WORKCARD_IMG_3}
posistion={'end'}
/>
        </div>
        <section className="mt-75 case_study">
          <Container>
            <Row>
              <Col lg={12} className="">
                <div className="content-view">
                  <div id="Overview" className="overview_content">
                    <Fade direction="up" duration={0}>
                      <div>
                        <p>
                        A jewelry manufacturing unit tackled challenges in quality control, vendor onboarding, and defect identification. Streamlined processes led to consistent quality and improved efficiency. A prominent jewelry manufacturing unit faced significant challenges in maintaining consistent quality standards across their vendor base and efficiently onboarding new vendors who met quality requirements. 
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="case_study_section mt-75 ">
              <h2 className="case_study_main_title">Challenges & Potential</h2>

              <h1 className="case_study_main_subtitle">Navigating the Complexities of <br /> Jewelry Manufacturing</h1>
              <div className="mt-50">
               <CaseCard cardInfo={caseCardData} />
              </div>
            
            </div>





            <div className="case_study_section  mt-75 ">
              <h2 className="case_study_main_title">Solution & Implementation</h2>

              <h1 className="case_study_main_subtitle">Streamlining Quality Control</h1>
              <div className="mt-50">
              <Row>
                {simplecardData?.map((item, index) => (
                  <Col lg={6} key={index} className="mb-24">
                    <SimpleCard
                      title={item?.title}
                      content={item?.content}
                      height={"100%"}
                    />
                  </Col>
                ))}
              </Row>
              </div>
            
            </div>

            <div className="case_study_section  mt-75 mb-60">
              <h2 className="case_study_main_title">Results</h2>

              <h1 className="case_study_main_subtitle">Transforming Jewelry Manufacturing <br /> for Quality Excellence</h1>
              <div className="mt-50">

                <CaseResults data={card_data}/>
              
              </div>
            
            </div>
          </Container>


        </section>
        <div className="case_page">
        <ServiceSubBanner bg={PHARM_SUBBANNER} title="Kickstart Your Dream Project With Us" contact="Contact Us"
        contentPadding="20%"
        padding = "20%" /> 
         </div>
        <RelatedCaseStudy filterCol="Jewellery Manufacturing" />
            <ContactNew />
        <Footer />

      </div>
    </motion.div>
  );
};

export default Jewel;





