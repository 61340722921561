import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Newnavigation from '../../components/new-navigation/new_navigation';
import AboutusBanner from '../../components/Aboutus-banner/AboutusBanner';
import ServiceSubBanner from '../../components/service-sub-banner/ServiceSubBanner';
import Aboutbannercount from '../../components/Aboutbannercount/Aboutbannercount';
import Ourmissioninabout from '../../components/Ourmissioninabout/Ourmissioninabout';
import ContactNew from '../../components/contact-form/ContactNew';
import Who_we_are from '../../components/Whoweareabout/Whoweareabout';
import What_sets_us from '../../components/What-Sets-Us-Apart/What-Sets-Us-Apart';
import Ourcorevalues from '../../components/Our-core-values/Our-core-values';
import ClientsAboutpage from '../../components/Clients-in-aboutpage/Clients-in-aboutpage';
import OurcoreTeam from '../../components/Our-Core-Team-Leaders/Our-Core-Team-Leaders';
import OurTeam from '../../components/Our-team-about/Our-team-about';
import Footer from '../../components/footer/Footer';
import OurHistory from '../../components/Our-history-about/Our-history-about';
import { ABOUTUS_BG, PHARM_SUBBANNER, SERVICE_SUB_BG } from '../../assets/images';
import { about_heading } from '../../utils/aboutbanner';
import { AboutSeo } from '../../utils/Seo';
import HeaderSeo from '../../components/HeaderSeo/Headerseo';



const AboutusPage = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className='service-page'>
        <Newnavigation routeName="About-us" />
        <HeaderSeo data={AboutSeo} />
        <AboutusBanner bg={ABOUTUS_BG} data={about_heading} />
        <Aboutbannercount />

        {/* <Ourmissioninabout /> */}
        <Who_we_are />
        <What_sets_us />
        <OurcoreTeam />
        {/* <OurTeam /> */}

        <ServiceSubBanner
          bg={PHARM_SUBBANNER}
          title="Ready to innovate and grow?"
          content='Come along with us on this thrilling path to digital transformation. Whether your goal is to elevate customer experiences, streamline your operations, or delve into new technological realms, Techno Genesis is ready to support and guide you every step of the way.'
          contact="Contact Us"
          carrersbtn="true"
          contentPadding="20%"
          padding="5%" />


        <ContactNew />
        <Footer />
      </div>
    </motion.div>
  )
}

export default AboutusPage