import React, { useEffect } from "react";
import Banner from "../../components/banner/banner";
import { CASE_BG_1, CASE_IMG_1, PHARM_SUBBANNER, WORKCARD_BG_3, WORKCARD_BG_4, WORKCARD_IMG_3, WORKCARD_IMG_4 } from "../../assets/images";
import Newnavigation from "../../components/new-navigation/new_navigation";
import { Col, Container, Row } from "react-bootstrap";
import RelatedCaseStudy from "../../components/case-studies/RelatedCaseStudy";
import ContactNew from "../../components/contact-form/ContactNew";
import Footer from "../../components/footer/Footer";
import HeaderSeo from "../../components/HeaderSeo/Headerseo";
import { CaseStudySeo } from "../../utils/Seo";
import { motion } from "framer-motion";
import { Fade } from "react-awesome-reveal";
import { SimpleCard } from "../../components/simple-card/SimpleCard";
import './case-study.css'
import CaseResults from "../../components/case-study-result/CaseResults";
import CaseCard from "../../components/case-card/CaseCard";
import ServiceSubBanner from "../../components/service-sub-banner/ServiceSubBanner";

const Finace = () => {

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const simplecardData = [
    {
      title: "Financial Management",
      content:
        "Automatically categorize expenses based on transaction data with a clear overview of their expenditures",
    },
    {
      title: "Real Time Budget",
      content:
        "Notifies if budget limit exceeds and allocate budget depends criteria and it works on real-time budget tracking.",
    },
    {
      title: "Analysis",
      content:
        "Predictive cash flow analysis tool, forecasting future income & expenses. Received alerts for cash flow gaps and proactively plan to avoid the financial stress at the end of the month.",
    },
    {
      title: "Approvals",
      content:
        "It streamlines the lending process by automate document verification and credit checks, ensures that faster and accurate credit approvals.",
    },
    {
      title: "Claim",
      content:
        "Allow to upload receipts direct to the relevant transaction for concern approval if requires and provide feedback.",
    },
    {
      title: "Credit Card",
      content:
        "Offering real time updates on balances, expenses and credit limits. Sending alerts and automate credit card bill payments on preference.",
    },
  ];

  const card_data = [
    {
        id:"01",
        title:"Accuracy",
        content:"Increase in accuracy of spent analysis and take decisions about their financial priorities."
    },
    {
        id:"02",
        title:"Awareness",
        content:"Aware the budget on the monthly basis and control their financial goals."
    },
    {
        id:"03",
        title:"Utilization",
        content:"Reduces the time taken for credit approval results in higher utilization of lending services."
    },
    {
        id:"04",
        title:"Delinquency",
        content:"Better credit scores as a result of improved credit card management."
    },
    {
      id:"05",
      title:"Consistency",
      content:"Shows an improvement in the consistency of budget tracking and financial planning."
  },
   

  ]

   const caseCardData =[

    {
        title:"Lack of Data",
        content:"Manual trade execution processes resulted in delays and inefficiencies."
    },
    {
        title:"Real Time Spending",
        content:"Ensuring secure trading experiences for clients was a top priority.."
    },
    {
        title:"Cash Flow",
        content:"The absence of real-time market data hindered traders' decision-making."
    },
    {
      title:"Delays",
      content:"Investors struggled to stay updated on market changes and portfolio movements in real-time."
  },
    {
      title:"Limits",
      content:"Clients lacked effective tools to monitor their investments and receive personalized recommendations."
  },

   ]


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="work_page ">
        <HeaderSeo data={CaseStudySeo} />
        <Newnavigation isblack={true} />
        <div id="Overview">
        <Banner width={'65%'} bg={WORKCARD_BG_4} smalltext={'Case Study 4'} title={'Finance'}img={WORKCARD_IMG_4} posistion={'end'}/>
        </div>
        <section className="mt-75 case_study">
          <Container>
            <Row>
              <Col lg={12} className="">
                <div className="content-view">
                  <div id="Overview" className="overview_content">
                    <Fade direction="up" duration={0}>
                      <div>
                        <p>
                        A leading finance company addressed a various aspects of personal finance including spent analysis, budget allocation, cash flow management, receipt tracking and managing credit card. The companies commitment to enhance financial goals collectively and the outcomes achieved in fostering financial harmony.
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="case_study_section mt-75 ">
              <h2 className="case_study_main_title">Challenges & Potential</h2>

              <h1 className="case_study_main_subtitle">Enhancing Payout Processes</h1>
              <div className="mt-50">
               <CaseCard cardInfo={caseCardData} />
              </div>
            
            </div>





            <div className="case_study_section  mt-75 ">
              <h2 className="case_study_main_title">Solution & Implementation</h2>

              <h1 className="case_study_main_subtitle">Modernizing Payouts for Efficiency <br /> and Transparency</h1>
              <div className="mt-50">
              <Row>
                {simplecardData?.map((item, index) => (
                  <Col lg={6} key={index} className="mb-24">
                    <SimpleCard
                      title={item?.title}
                      content={item?.content}
                      height={"100%"}
                    />
                  </Col>
                ))}
              </Row>
              </div>
            
            </div>

            <div className="case_study_section  mt-75 mb-60">
              <h2 className="case_study_main_title">Results</h2>

              <h1 className="case_study_main_subtitle">Revolutionizing Payout Processes</h1>
              <div className="mt-50">

                <CaseResults data={card_data}/>
              
              </div>
            
            </div>
          </Container>


        </section>
        <div className="case_page">
        <ServiceSubBanner bg={PHARM_SUBBANNER} title="Kickstart Your Dream Project With Us" contact="Contact Us"
        contentPadding="20%"
        padding = "20%" /> 
         </div>
        <RelatedCaseStudy filterCol="Finance" />
            <ContactNew />
        <Footer />

      </div>
    </motion.div>
  );
};

export default Finace;








